export enum TransformJobStateName {
  DEFAULT = 'NEW',
  NEW = 'NEW',
  QUEUED = 'QUEUED',
  DONE = 'DONE',
  ERRORED = 'ERRORED',
  CANCELLED = 'CANCELLED'
}

export enum DomoSetTransformJobType {
  MODIFY = 'MODIFY'
}

export enum DomoSetTransformOperationType {
  CREATE = 'CREATE',
  READ = 'READ',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE'
}

export class DomoSetTransformOperation {
  type!: DomoSetTransformOperationType;
  domoSetUuid!: string;
  filter?: any;
  appliesTo?: any; // This is not implemented
  patches?: any[];
  transformers?: any[]; // We don't support this yet
}

export class DomoSetTransformJobStats {
  total!: number;
  totalsByEntityType!: Record<string, number>;
}

export interface TransformJobModel {
  uuid?: string;
  label: string;
  targetDomoSetUuid: string;
  state: TransformJobStateName;
  operations: DomoSetTransformOperation[];
  stats: DomoSetTransformJobStats;
  updatedAt?: string;
  createdAt?: string;
}

export interface RunDomoSetPatch {
  label?: string;
  filter: string; // JSON
  patch: string; // JSON
}
