import '@/lib/log';
import { GetItemsOptions } from '@/module/api/common';
import { BaseItemState, BaseUseItemModel } from '@/module/shared/base.use-crud.interface';
import { computed, ref } from '@vue/composition-api';
import axios from 'axios';
import Vue from 'vue';
import {
  RunDomoSetPatch,
  TransformJobModel,
  TransformJobStateName
} from './transform-job.interface';

const apiPath = `${process.env.VUE_APP_API_BASE_URL}/workspace-ui/domo-set/{domoSetUuid}/transform-job`;
const patchPath = `${process.env.VUE_APP_API_BASE_URL}/workspace-ui/domo-set/{domoSetUuid}/patch`;
const defaultMaxResultLimit = 10;

export const emptyItem = (): TransformJobModel => {
  return {
    uuid: '',
    targetDomoSetUuid: '',
    label: '',
    operations: [],
    state: TransformJobStateName.NEW,
    stats: {
      total: 0,
      totalsByEntityType: {}
    },
    createdAt: '',
    updatedAt: ''
  };
};

const state: BaseItemState<TransformJobModel> = {
  items: ref<TransformJobModel[]>([]),
  selectedItemId: ref(''),
  selectedItem: ref(emptyItem()),
  createdItemId: ref(''),
  createdItem: ref(emptyItem()),
  isLoading: ref(false)
};

export function useTransformJobApi(
  useGlobalQueryParams: Record<any, any> | undefined = undefined
): BaseUseItemModel<TransformJobModel> & any {
  Vue.$log.debug('Loaded with query', useGlobalQueryParams);

  const emptyTransformJobModel = () => {
    return emptyItem();
  };

  const selectItem = async (uuid: string): Promise<TransformJobModel | undefined> => {
    if (!uuid) {
      Vue.$log.debug(`Setting selected item to empty because ${uuid} was given`);
      state.selectedItemId.value = '';
      state.selectedItem.value = emptyTransformJobModel();
      return;
    }
    Vue.$log.debug(`Selecting item ${uuid} from items: `, state.items.value);
    const item = state.items.value.find((i: TransformJobModel) => i.uuid === uuid);
    if (!item) {
      Vue.$log.debug(`Item ${uuid} not found`);
      return undefined;
    }
    Vue.$log.debug(`Selected item ${uuid}`, item);
    state.selectedItemId.value = item?.uuid ? item.uuid : '';
    state.selectedItem.value = item;
    return state.selectedItem.value;
  };

  const selectItemBy = async (
    key: string,
    value: string
  ): Promise<TransformJobModel | undefined> => {
    if (key && value) {
      Vue.$log.debug(`Selecting item ${value} from items: `, state.items.value);
      const item = state.items.value.find((i: any) => i?.[key] === value);
      if (item) {
        state.selectedItemId.value = item?.uuid ? item.uuid : '';
        Vue.$log.debug({ selected: item });
        state.selectedItem.value = item;
        return state.selectedItem.value;
      }
    }
  };

  const getItems = async (domoSetUuid: string, options?: GetItemsOptions) => {
    try {
      state.isLoading.value = true;
      const combinedQueryParams = Object.assign(
        {},
        useGlobalQueryParams,
        {
          limit: defaultMaxResultLimit
        },
        options?.query
      );
      Vue.$log.debug('Transform Job: Fetching items with query params: ', combinedQueryParams);
      const res = await axios.get(apiPath.replace('{domoSetUuid}', domoSetUuid), {
        params: combinedQueryParams
      });
      Vue.$log.debug('Returned from API: ', res.data);
      // Update the list of items.
      state.items.value = res.data?._embedded ? res.data._embedded : res.data;
      // Update the selected item.
      if (state.selectedItem?.value?.uuid !== undefined) {
        for (const item of state.items.value) {
          if (item?.uuid === state.selectedItem.value.uuid) {
            state.selectedItem.value = item;
          }
        }
      }
      return options?.raw ? res.data : state.items.value;
    } catch (err) {
      const errMessage = 'Error loading.';
      Vue.$log.error(errMessage, err);
    } finally {
      state.isLoading.value = false;
    }
  };

  const addOrReplaceItemInItems = (newItem: TransformJobModel): void => {
    if (state.items.value.some((item: TransformJobModel) => item.uuid === newItem.uuid)) {
      state.items.value.forEach((item: TransformJobModel, i: number) => {
        if (item.uuid === newItem.uuid) {
          Vue.$log.debug(`Replacing item in items: ${newItem.uuid}`);
          // Note: we must splice instead of assign to trigger render update.
          state.items.value.splice(i, 1, newItem);
        }
      });
    } else {
      Vue.$log.debug(`Adding load job to items: ${newItem.uuid}`);
      state.items.value.push(newItem);
    }
    if (newItem?.uuid !== undefined && state.selectedItem?.value?.uuid === newItem?.uuid) {
      Vue.$log.debug(`Replacing selected item: ${newItem.uuid}`);
      state.selectedItem.value = newItem;
    }
  };

  const getItem = async (domoSetUuid: string, uuid: string) => {
    if (!domoSetUuid || !uuid) {
      throw new Error('Can not get item without domo set UUID or ID');
    }
    try {
      state.isLoading.value = true;
      const res = await axios.get<TransformJobModel>(
        `${apiPath.replace('{domoSetUuid}', domoSetUuid)}/${uuid}`
      );
      Vue.$log.debug('Returned from API: ', res.data);
      if (!res?.data?.uuid) {
        Vue.$log.debug('FAIL! uuid is not in response so cannot add item');
        return res.data;
      }
      addOrReplaceItemInItems(res.data);
      return res.data;
    } catch (err) {
      const errMessage = 'Error loading.';
      Vue.$log.error(errMessage, err);
    } finally {
      state.isLoading.value = false;
    }
  };

  const patch = async (
    domoSetUuid: string,
    item: RunDomoSetPatch
  ): Promise<TransformJobModel | undefined> => {
    Vue.$log.debug('Patching new item: ', item);
    state.isLoading.value = true;
    return axios
      .post<TransformJobModel>(
        `${patchPath.replace('{domoSetUuid}', domoSetUuid)}`,
        {
          label: item.label ? item.label : undefined,
          filter: item.filter,
          patch: item.patch
        },
        {
          headers: { 'content-type': 'application/json' }
        }
      )
      .then((res) => {
        if (res.data) {
          Vue.$log.debug('Successfully patcbed item. Refreshing items.');
          state.createdItem.value = res.data;
          state.createdItemId.value = res.data.uuid!; // eslint-disable-line
          return res.data;
        } else {
          const errMessage = 'Invalid response patching item';
          Vue.$log.error(errMessage, res);
          return undefined;
        }
      })
      .catch((err) => {
        const errMessage = 'Error caught patching item.';
        Vue.$log.error(errMessage, err);
        return undefined;
      })
      .finally(() => {
        state.isLoading.value = false;
      });
  };

  /**
   * Refresh the selected item. If it is not selected, select it.
   */
  const refreshItem = async (
    domoSetUuid: string,
    uuid: string,
    forceRefresh = false
  ): Promise<void> => {
    Vue.$log.debug(
      `Refreshing TRANSFORM JOB domo set ${domoSetUuid} job ${uuid} with force refresh ${forceRefresh}`
    );
    // First, attempt to select the item if it is not selected.
    if (state.selectedItem?.value?.uuid !== uuid) {
      await selectItem(uuid);
    }
    // Next, if we are forcing refresh or the item is not found (which we infer
    // from an empty selected item), go get it.
    if (uuid && (forceRefresh || state.selectedItem?.value?.uuid !== uuid)) {
      await getItem(domoSetUuid, uuid);
    }
    // Finally, if the selected item doesn't match, select it.
    if (state.selectedItem?.value?.uuid !== uuid) {
      await selectItem(uuid);
    }
  };

  return {
    items: computed(() => state.items.value),
    emptyItem: computed(() => emptyItem),
    selectedItem: computed(() => state.selectedItem.value),
    selectedItemId: computed(() => state.selectedItemId.value),
    createdItemId: computed(() => state.createdItemId.value),
    createdItem: computed(() => state.createdItem.value),
    isLoading: computed(() => state.isLoading.value),
    selectItemBy,
    refreshItem,
    getItem,
    getItems,
    patch,
    selectItem,
    emptyTransformJobModel
  };
}
