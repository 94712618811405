





































































































































































































































































import DomainWorkspaceToolbar from '@/components/navigation/DomainWorkspaceToolbar.vue';
import RefreshButton from '@/components/navigation/RefreshButton.vue';
import { MAX_ITEMS_PER_PAGE, RunQueryOptions } from '@/module/api/common';
import { useDomainApi } from '@/module/api/domain';
import { DomoModel } from '@/module/api/domo';
import { getFriendlyLoadJobTypeName, useLoadJobApi } from '@/module/api/load-job';
import { RunDomoSetPatch, useTransformJobApi } from '@/module/api/transform-job';
import router from '@/router';
import { DateTimeMethod, formatDbDate, saveTextToClipboard, usePagination } from '@/utils';
import DomainDialog from '@/views/app/domains/DomainDialog.vue';
import { computed, defineComponent, reactive, ref, toRefs } from '@vue/composition-api';
import Vue from 'vue';
import { createToastInterface } from 'vue-toastification';

const {
  areThereMorePages,
  getNextPageQueryParams,
  emptyPageableTable,
  resetPageableTable,
  cachePage,
  applyPageChange,
} = usePagination(MAX_ITEMS_PER_PAGE);

const toast = createToastInterface({ maxToasts: 3 });

const { selectedItem: selectedDomain, isLoading: isDomainLoading, refreshItem: refreshDomain } = useDomainApi();

const { isLoading: isLoadJobLoading, selectedItem: selectedLoadJob, refreshItem: refreshLoadJob } = useLoadJobApi();

const {
  getItems: getTransformJobs,
  items: transformJobs,
  isLoading: isTransformJobLoading,
  patch: runPatchTransformJob,
} = useTransformJobApi();

const domainDialog = ref<any>();

const emptyRunDomoSetPatch = (): RunDomoSetPatch => {
  return {
    label: '',
    filter: '',
    patch: '',
  };
};

const emptyState = () => {
  return {
    dialog: false,
    loadJobUuid: '',
    domainUuid: '',
    domoSetUuid: '',
    filter: [] as Partial<Record<string, any>>[],
    systemFilters: { 'filter[targetDomoSetUuid]': '' },
    transformJobDetailsDialog: false,
    runPatchDialog: false,
    data: [] as Partial<DomoModel[]>,
    currentTransformJob: {} as Partial<DomoModel>,
    runPatch: emptyRunDomoSetPatch(),
    isValidRunPatchForm: false,
    hasFilterJsonError: false,
    hasPatchJsonError: false,
  };
};

const state = reactive(emptyState());

const rules = {
  required: [(v: string) => !!v || 'Required.'],
  validFilter: [
    (v: string) => {
      if (!v || state.hasFilterJsonError) {
        return 'The field is invalid.';
      }
      return true;
    },
  ],
  validPatch: [
    (v: string) => {
      if (!v || state.hasPatchJsonError) {
        return 'The field is invalid.';
      }
      return true;
    },
  ],
};

const transformJobTable = reactive({
  ...emptyPageableTable(),
  headers: [
    { text: '', value: 'uuid', width: '32px', sortable: false },
    { text: 'State', value: 'state', sortable: false },
    { text: 'Affected', value: 'stats', sortable: false },
    // { text: 'Operation', value: 'operations', sortable: false },
    { text: 'Label', value: 'label', sortable: false },
    { text: 'Date', value: 'updatedAt', sortable: false },
  ],
  itemsPerPage: MAX_ITEMS_PER_PAGE,
});

const viewDomain = () => {
  Vue.$log.debug('Clicked view dialog');
  domainDialog.value.view(selectedDomain);
};

const addFilter = (filter: any[]) => {
  filter.push({ key: '', value: '' });
};

const filterAsQueryParams = computed(() => {
  return state.filter.reduce((query: any, rec: any) => {
    if (rec.key && rec.key !== '' && rec.value) {
      query[rec.key] = rec.value;
    }
    return query;
  }, {});
});

const queryParamFormattedFilter = computed(() => {
  return state.filter.map(rec => {
    return { [rec.key]: rec.value };
  });
});

const getTransformJobSearchResults = async () => {
  const getTransformJobsRes = await getTransformJobs(state.domoSetUuid, {
    query: { ...filterAsQueryParams.value, ...state.systemFilters },
    raw: true,
  });
  resetPageableTable(transformJobTable);
  const areMorePages = areThereMorePages(getTransformJobsRes);
  if (areMorePages) {
    transformJobTable.pageCount = 2;
  }
  transformJobTable.data = getTransformJobsRes._embedded;
  cachePage(transformJobTable, transformJobTable.data);
};

const addDefaultFilters = () => {
  Vue.$log.debug('When adding default filters, this is our domain and state', state, selectedDomain);
  if (state.domoSetUuid !== '' && state.systemFilters['filter[targetDomoSetUuid]'] !== state.domoSetUuid) {
    state.systemFilters['filter[targetDomoSetUuid]'] = state.domoSetUuid;
  }
};

const refreshItems = async (forceUpdate = false): Promise<void> => {
  await refreshDomain(state.domainUuid, forceUpdate);
  await refreshLoadJob(state.loadJobUuid, forceUpdate);

  if (forceUpdate || state.domoSetUuid !== selectedLoadJob.value?.domoSetUuid) {
    state.domoSetUuid = selectedLoadJob.value.domoSetUuid;
  }

  if (!state.domoSetUuid) {
    Vue.$log.error(
      'Could not refresh all items. Unable to derive domoSetUuid from loadJob. Returning without refreshing transform jobs',
    );
    return;
  }
  addDefaultFilters();
  await getTransformJobSearchResults();
};

const queryTransformJobs = async (runQueryOptions: RunQueryOptions) => {
  const pageQuery = runQueryOptions.nextPage
    ? getNextPageQueryParams({
        page: transformJobTable.page,
      })
    : { page: transformJobTable.page };
  pageQuery.limit = 50;
  const getParams = {
    query: {
      ...pageQuery,
      ...filterAsQueryParams.value,
      ...state.systemFilters,
    },
    raw: true,
  };
  const response = await getTransformJobs(state.domoSetUuid, getParams);
  transformJobTable.data = response?._embedded;
  cachePage(transformJobTable, transformJobTable.data);
  const areMorePages = areThereMorePages(response);
  if (areMorePages) {
    transformJobTable.pageCount = transformJobTable.pageCount + 1;
  }
  return transformJobTable.data;
};

const getNextPage = async () => {
  applyPageChange(transformJobTable, async () => await queryTransformJobs({ nextPage: true }));
};

const queryDomosWithFilter = () => {
  getTransformJobs(state.domoSetUuid, { ...filterAsQueryParams.value, ...state.systemFilters });
};

const showDomoDetailDialog = (item: DomoModel) => {
  state.currentTransformJob = item;
  state.transformJobDetailsDialog = true;
};

const showRunPatchDialog = (item: DomoModel) => {
  state.runPatch = emptyRunDomoSetPatch();
  state.runPatchDialog = true;
};

const reset = async () => {
  Object.assign(state, emptyState());
};

const clipyboardCopyWithNotification = (textToCopy: string) => {
  saveTextToClipboard(textToCopy);
  toast.success('Copied text to clipboard.');
};

const jsonPretty = (obj: any) => {
  return JSON.stringify(obj, null, 2);
};

const goToLoadJobOperationBrowserPage = () => {
  router.push({
    name: 'LoadJobOperationBrowser',
    params: {
      uuid: selectedDomain.value?.uuid,
      jobUuid: selectedLoadJob.value?.uuid,
      backLinkOverride: `/domain/${state.domainUuid}/job/import/${state.loadJobUuid}/transform`,
    },
  });
};

class JsonPatchValidation {
  error?: string;
  parsedJson?: any;
  prettyJson: string;
}

const getJsonValidation = (jsonInput: string): JsonPatchValidation => {
  let jsonValue = '';
  try {
    jsonValue = JSON.parse(jsonInput);
  } catch (e) {
    return {
      prettyJson: '',
      parsedJson: undefined,
      error: e.message,
    };
  }
  return {
    prettyJson: jsonPretty(jsonValue),
    parsedJson: jsonValue,
    error: undefined,
  };
};

const runPatchFilterJsonPretty = computed((): { error?: string; prettyJson: string } => {
  const jsonValidation = getJsonValidation(state.runPatch.filter);
  if (jsonValidation.error) {
    state.hasFilterJsonError = true;
    return jsonValidation;
  }
  if (!jsonValidation.parsedJson || typeof jsonValidation.parsedJson !== 'object') {
    state.hasFilterJsonError = true;
    return {
      ...jsonValidation,
      error: 'The filter must be a key-value object',
    };
  }
  state.hasFilterJsonError = false;
  return jsonValidation;
});

const allowedPatchOps = ['add', 'remove', 'replace', 'copy', 'move'];

const getPathOperationValidation = (operation: any): string | undefined => {
  if (typeof operation !== 'object') {
    return `The operation must be a key-value object`;
  }
  const keys = Object.keys(operation);
  if (keys.indexOf('op') === -1) {
    return 'You must specify an operation ("op")';
  }
  const opType = operation.op;
  if (allowedPatchOps.indexOf(opType) === -1) {
    return `The operation must be one of ${allowedPatchOps.join(', ')} and you gave: ${opType}`;
  }
  if (keys.indexOf('path') === -1) {
    return 'You must specify a path ("path")';
  }
  if (operation.path.indexOf('/') !== 0) {
    return 'The path must be a valid JSON Path';
  }
  if (opType === 'remove') {
    // No additional validations, its valid.
    return undefined;
  }
  if (opType === 'move' || opType === 'copy') {
    if (keys.indexOf('from') === -1) {
      return `You must specify the from to do a ${opType} ("from")`;
    }
    if (operation.from.indexOf('/') !== 0) {
      return 'The from must be a valid JSON Path';
    }
    if (operation.from === operation.path) {
      return 'The from and path must be different JSON Paths';
    }
    // No additional validations, its valid.
    return undefined;
  }
  if (keys.indexOf('value') === -1) {
    return `You must specify the value to ${opType} ("value")`;
  }
  return undefined;
};

const runPatchPatchJsonPretty = computed(
  (): JsonPatchValidation => {
    const jsonValidation = getJsonValidation(state.runPatch.patch);
    if (jsonValidation.error) {
      state.hasPatchJsonError = true;
      return jsonValidation;
    }
    if (!jsonValidation.parsedJson || !Array.isArray(jsonValidation.parsedJson)) {
      state.hasPatchJsonError = true;
      return {
        ...jsonValidation,
        error: 'The patch must be an array of operations',
      };
    }
    if (jsonValidation.parsedJson.length < 1) {
      state.hasPatchJsonError = true;
      return {
        ...jsonValidation,
        error: 'You must have at least one operation',
      };
    }
    for (const key in jsonValidation.parsedJson) {
      const operationValidationError = getPathOperationValidation(jsonValidation.parsedJson[key]);
      if (operationValidationError) {
        state.hasPatchJsonError = true;
        return {
          ...jsonValidation,
          error: `Operation at array index ${key} is invalid: ${operationValidationError}`,
        };
      }
    }
    state.hasPatchJsonError = false;
    return jsonValidation;
  },
);

const runPatchFilterExamples: { title: string; value: any }[] = [
  {
    title: 'Example 1: Filter to all USER_PROFILE operations that are going to be CREATE.',
    value: jsonPretty({
      entityType: 'USER_PROFILE',
      operationType: 'CREATE',
    }),
  },
  {
    title: 'Example 2: Filter to all USER_PROFILE operations whose entity ID are in a list',
    value: jsonPretty({
      entityType: 'USER_PROFILE',
      entityId: { $in: ['1', '2', '3'] },
      operationType: 'CREATE',
    }),
  },
];

const runPatchPatchExamples: { title: string; value: any }[] = [
  {
    title: 'Example 1: Remove verintFeatures from USER_PROFILE.',
    value: jsonPretty([
      {
        op: 'remove',
        path: '/domb/verintFeatures',
      },
    ]),
  },
  {
    title: 'Example 2: Append CAN_RUN_WEB_CLIENT permission to USER_PROFILE agent role',
    value: jsonPretty([
      {
        op: 'add',
        path: '/domb/agentRole/permissions/9999',
        value: 'CAN_RUN_WEB_CLIENT',
      },
    ]),
  },
];

const executeRunPatch = () => {
  let params: RunDomoSetPatch;
  try {
    params = {
      label: state.runPatch.label,
      filter: JSON.parse(state.runPatch.filter),
      patch: JSON.parse(state.runPatch.patch),
    };
  } catch (e) {
    toast.error('Patch failed to run');
    return;
  }
  runPatchTransformJob(state.domoSetUuid, params)
    .then((result: any) => {
      if (!result) {
        toast.error('Patch failed to run');
        return;
      }
      toast.success('Patch was run');
      state.runPatchDialog = false;
      return refreshItems();
    })
    .catch(e => {
      toast.error('Patch failed to run');
    });
};

export default defineComponent({
  name: 'TransformJobBrowser',
  components: { RefreshButton, DomainDialog, DomainWorkspaceToolbar },
  props: {
    uuid: {
      type: String,
    },
    jobUuid: {
      type: String,
    },
  },
  setup(props) {
    reset();
    state.loadJobUuid = props.jobUuid || '';
    state.domainUuid = props.uuid || '';
    refreshItems();
    return {
      ...toRefs(state),
      reset,
      formatDbDate,
      refreshItems,
      isUserAdmin: true,
      transformJobs,
      domainDialog,
      viewDomain,
      isTransformJobLoading,
      isDomainLoading,
      isLoadJobLoading,
      getFriendlyLoadJobTypeName,
      selectedLoadJob,
      applyPageChange,
      domoTable: transformJobTable,
      addFilter,
      saveTextToClipboard,
      queryParamFormattedFilter,
      DateTimeMethod,
      queryDomos: queryTransformJobs,
      queryDomosWithFilter,
      jsonPretty,
      clipyboardCopyWithNotification,
      jobType: 'Import',
      backLink: `/domain/${props.uuid}/job/import/${props.jobUuid}`,
      showDomoDetailDialog,
      showRunPatchDialog,
      runPatchFilterExamples,
      runPatchPatchExamples,
      runPatchFilterJsonPretty,
      runPatchPatchJsonPretty,
      getDomoSearchResults: getTransformJobSearchResults,
      selectedDomain,
      commonFilters: ['filter[label]', 'filter[state]', 'filter[uuid]'],
      getNextPage,
      goToLoadJobOperationBrowserPage,
      rules,
      executeRunPatch,
    };
  },
});
