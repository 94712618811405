var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("domain-workspace-toolbar", {
                attrs: {
                  backLink: _vm.backLink,
                  subtitle:
                    _vm.getFriendlyLoadJobTypeName(_vm.selectedLoadJob.type) +
                    " Job",
                  subsubtitle: "Transform Jobs"
                },
                scopedSlots: _vm._u([
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _c("refresh-button", {
                          staticClass: "mr-2",
                          attrs: { refreshFunction: _vm.refreshItems }
                        })
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mb-4" },
        [
          _c(
            "v-card-title",
            [
              _c("div", { staticClass: "ml-4 mb-2" }, [
                _vm._v(_vm._s(_vm.selectedLoadJob.label)),
                _c("br"),
                _vm._v("Transform Jobs")
              ]),
              _c("v-spacer"),
              _c(
                "p",
                [
                  _c(
                    "v-btn",
                    { on: { click: _vm.goToLoadJobOperationBrowserPage } },
                    [_vm._v("Browse Operations")]
                  )
                ],
                1
              ),
              _c(
                "p",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-8",
                      attrs: { color: "error" },
                      on: { click: _vm.showRunPatchDialog }
                    },
                    [_vm._v("Add Patch")]
                  )
                ],
                1
              ),
              _c(
                "v-expansion-panels",
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v(
                          "Filters " + _vm._s(_vm.queryParamFormattedFilter)
                        )
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c(
                            "v-container",
                            [
                              _vm._l(_vm.filter, function(
                                filterItem,
                                filterIndex
                              ) {
                                return _c(
                                  "v-row",
                                  { key: filterIndex },
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c("v-combobox", {
                                          attrs: {
                                            label: "Key",
                                            "single-line": "",
                                            "hide-details": "",
                                            items: _vm.commonFilters
                                          },
                                          model: {
                                            value: filterItem.key,
                                            callback: function($$v) {
                                              _vm.$set(filterItem, "key", $$v)
                                            },
                                            expression: "filterItem.key"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Value",
                                            "single-line": "",
                                            "hide-details": ""
                                          },
                                          model: {
                                            value: filterItem.value,
                                            callback: function($$v) {
                                              _vm.$set(filterItem, "value", $$v)
                                            },
                                            expression: "filterItem.value"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { staticClass: "col-1" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mt-6",
                                            attrs: { icon: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.filter.pop()
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [_vm._v("mdi-delete")])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.addFilter(_vm.filter)
                                            }
                                          }
                                        },
                                        [_vm._v("Add Filter")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { block: "", color: "primary" },
                  on: { click: _vm.getDomoSearchResults }
                },
                [_vm._v("Search")]
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.domoTable.headers,
                  loading:
                    _vm.isTransformJobLoading ||
                    _vm.isLoadJobLoading ||
                    _vm.isDomainLoading,
                  items: _vm.domoTable.data,
                  "items-per-page": _vm.domoTable.itemsPerPage,
                  page: _vm.domoTable.page,
                  "hide-default-footer": "",
                  dense: "",
                  "item-key": "uuid"
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.domoTable, "page", $event)
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.uuid",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", dark: "", icon: "" },
                            on: {
                              click: function($event) {
                                return _vm.showDomoDetailDialog(item)
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("mdi-eye")])],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "item.stats",
                    fn: function(ref) {
                      var item = ref.item
                      return [_vm._v(" " + _vm._s(item.stats.total) + " ")]
                    }
                  },
                  {
                    key: "item.updatedAt",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.formatDbDate(item.updatedAt)) + " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c(
                "div",
                { staticClass: "text-center pt-2" },
                [
                  _c("v-pagination", {
                    attrs: { length: _vm.domoTable.pageCount },
                    on: { input: _vm.getNextPage },
                    model: {
                      value: _vm.domoTable.page,
                      callback: function($$v) {
                        _vm.$set(_vm.domoTable, "page", $$v)
                      },
                      expression: "domoTable.page"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "retain-focus": false, scrollable: "" },
          model: {
            value: _vm.transformJobDetailsDialog,
            callback: function($$v) {
              _vm.transformJobDetailsDialog = $$v
            },
            expression: "transformJobDetailsDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("span", { staticClass: "text-h5 mr-4" }, [
                    _vm._v(_vm._s(_vm.currentTransformJob.entityType))
                  ]),
                  _c("v-text-field", {
                    attrs: { label: "UUID", readonly: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "append",
                        fn: function() {
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "primary", dark: "", icon: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.clipyboardCopyWithNotification(
                                      _vm.currentTransformJob.uuid || ""
                                    )
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("mdi-content-copy")])],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.currentTransformJob.uuid,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTransformJob, "uuid", $$v)
                      },
                      expression: "currentTransformJob.uuid"
                    }
                  }),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.transformJobDetailsDialog = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close-circle")])],
                    1
                  )
                ],
                1
              ),
              _c("v-card-text", [
                _c("pre", [
                  _vm._v(
                    _vm._s(_vm.jsonPretty(_vm.currentTransformJob)) +
                      "\n                "
                  )
                ])
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function($event) {
                          _vm.transformJobDetailsDialog = false
                        }
                      }
                    },
                    [_vm._v(" Close ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "retain-focus": false, scrollable: "", persistent: true },
          model: {
            value: _vm.runPatchDialog,
            callback: function($$v) {
              _vm.runPatchDialog = $$v
            },
            expression: "runPatchDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("span", { staticClass: "text-h5 mr-4" }, [
                    _vm._v("Run a Patch")
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.runPatchDialog = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close-circle")])],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-expansion-panels",
                    { staticClass: "mb-8" },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v("Need help?")
                          ]),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "h3",
                                { staticClass: "text-decoration-underline" },
                                [_c("b", [_vm._v("Label:")])]
                              ),
                              _c("p", [
                                _vm._v(
                                  "If left empty it will automatically populate."
                                )
                              ]),
                              _c(
                                "h3",
                                { staticClass: "text-decoration-underline" },
                                [_c("b", [_vm._v("Filter:")])]
                              ),
                              _c("p", [
                                _c("b", [
                                  _vm._v(
                                    "You do not want to run a patch on ALL objects."
                                  )
                                ]),
                                _vm._v(
                                  " You will want to limit what you run the patch against. Specify a filter. You can use the "
                                ),
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.goToLoadJobOperationBrowserPage.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("Load Job Operation Browser")]
                                ),
                                _vm._v(
                                  " to build your filter and click the copy JSON Filter button and paste it here. "
                                )
                              ]),
                              _c("p", [
                                _vm._v(
                                  " The filter is basically a Mongo query, but the keys must be properties of the DOMO. Some typical filter examples: "
                                )
                              ]),
                              _vm._l(_vm.runPatchFilterExamples, function(
                                item,
                                index
                              ) {
                                return _c("div", { key: "filter" + index }, [
                                  _c("b", [_vm._v(_vm._s(item.title))]),
                                  _c("br"),
                                  _c("pre", [_vm._v(_vm._s(item.value))]),
                                  _c("br")
                                ])
                              }),
                              _c(
                                "h3",
                                { staticClass: "text-decoration-underline" },
                                [_c("b", [_vm._v("Patch:")])]
                              ),
                              _c("p", [
                                _vm._v(
                                  " Here you will write a JSON Patch, which is actually an array of operations, see the RFC for details. You will have noticed the system already has some Transform Jobs with patches that you can look at. Here are some examples: "
                                )
                              ]),
                              _vm._l(_vm.runPatchPatchExamples, function(
                                item,
                                index
                              ) {
                                return _c("div", { key: "patch" + index }, [
                                  _c("b", [_vm._v(_vm._s(item.title))]),
                                  _c("br"),
                                  _c("pre", [_vm._v(_vm._s(item.value))]),
                                  _c("br")
                                ])
                              }),
                              _c("p", [
                                _vm._v(
                                  "Here are some useful links about JSON Patch:"
                                )
                              ]),
                              _c("p", [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      target: "_blank",
                                      href:
                                        "https://datatracker.ietf.org/doc/html/rfc6902"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "https://datatracker.ietf.org/doc/html/rfc6902"
                                    )
                                  ]
                                )
                              ]),
                              _c("p", [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      target: "_blank",
                                      href: "https://jsonpatch.com/"
                                    }
                                  },
                                  [_vm._v("https://jsonpatch.com/")]
                                )
                              ]),
                              _c("p", [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      target: "_blank",
                                      href:
                                        "https://json-patch-builder-online.github.io/"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "https://json-patch-builder-online.github.io/"
                                    )
                                  ]
                                ),
                                _vm._v(
                                  " - Tip: you can go to a DOMO in the Operation Browser and copy the DOMO, and use this tool to generate a patch "
                                )
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-form",
                    {
                      model: {
                        value: _vm.isValidRunPatchForm,
                        callback: function($$v) {
                          _vm.isValidRunPatchForm = $$v
                        },
                        expression: "isValidRunPatchForm"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Label (optional)",
                                  type: "text",
                                  autofocus: "",
                                  outlined: "",
                                  dense: ""
                                },
                                model: {
                                  value: _vm.runPatch.label,
                                  callback: function($$v) {
                                    _vm.$set(_vm.runPatch, "label", $$v)
                                  },
                                  expression: "runPatch.label"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("h4", [_vm._v("Filter")]),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.runPatch.filter &&
                                        _vm.runPatchFilterJsonPretty.error,
                                      expression:
                                        "runPatch.filter && runPatchFilterJsonPretty.error"
                                    }
                                  ]
                                },
                                [
                                  _c("v-icon", { attrs: { color: "error" } }, [
                                    _vm._v("mdi-close-thick")
                                  ]),
                                  _vm._v(
                                    " Invalid filter: " +
                                      _vm._s(
                                        _vm.runPatchFilterJsonPretty.error
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.runPatch.filter &&
                                        !_vm.runPatchFilterJsonPretty.error,
                                      expression:
                                        "runPatch.filter && !runPatchFilterJsonPretty.error"
                                    }
                                  ]
                                },
                                [
                                  _c("v-icon", [_vm._v("mdi-check-bold")]),
                                  _vm._v(" Valid filter")
                                ],
                                1
                              ),
                              _c("v-textarea", {
                                attrs: {
                                  label: "Filter (JSON key-value)",
                                  required: "",
                                  outlined: "",
                                  dense: "",
                                  rows: "4",
                                  rules: _vm.rules.validFilter
                                },
                                model: {
                                  value: _vm.runPatch.filter,
                                  callback: function($$v) {
                                    _vm.$set(_vm.runPatch, "filter", $$v)
                                  },
                                  expression: "runPatch.filter"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("h4", [_vm._v("Patch")]),
                              _c("p", [
                                _vm._v(
                                  ' Write a valid JSON Patch (see RFC 6902). Typically, you want to do a "replace" or "remove" operation. '
                                )
                              ]),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.runPatch.patch &&
                                        _vm.runPatchPatchJsonPretty.error,
                                      expression:
                                        "runPatch.patch && runPatchPatchJsonPretty.error"
                                    }
                                  ]
                                },
                                [
                                  _c("v-icon", { attrs: { color: "error" } }, [
                                    _vm._v("mdi-close-thick")
                                  ]),
                                  _vm._v(
                                    " Invalid patch: " +
                                      _vm._s(
                                        _vm.runPatchPatchJsonPretty.error
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.runPatch.patch &&
                                        !_vm.runPatchPatchJsonPretty.error,
                                      expression:
                                        "runPatch.patch && !runPatchPatchJsonPretty.error"
                                    }
                                  ]
                                },
                                [
                                  _c("v-icon", [_vm._v("mdi-check-bold")]),
                                  _vm._v(" Valid patch")
                                ],
                                1
                              ),
                              _c("v-textarea", {
                                attrs: {
                                  label: "JSON Patch",
                                  required: "",
                                  outlined: "",
                                  dense: "",
                                  rows: "10",
                                  rules: _vm.rules.validPatch
                                },
                                model: {
                                  value: _vm.runPatch.patch,
                                  callback: function($$v) {
                                    _vm.$set(_vm.runPatch, "patch", $$v)
                                  },
                                  expression: "runPatch.patch"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        disabled:
                          !_vm.isValidRunPatchForm ||
                          _vm.isDomainLoading ||
                          _vm.isLoadJobLoading ||
                          _vm.isTransformJobLoading
                      },
                      on: { click: _vm.executeRunPatch }
                    },
                    [_vm._v(" Execute ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "secondary", text: "" },
                      on: {
                        click: function($event) {
                          _vm.runPatchDialog = false
                        }
                      }
                    },
                    [_vm._v(" Close ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("domain-dialog", { ref: "domainDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }